import React, { useEffect, useState } from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box from "@onnit-js/ui/components/box/Box";
import { List, ListItem, Sup, Text } from "@onnit-js/ui/components/text";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import { Img, Picture } from "@onnit-js/ui/components/image";
import ProductImageGrid from "@onnit-js/ui/components/module/ProductImageGrid";
import { TwelveColumn } from "@onnit-js/ui/components/module/grid/12/Grid";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import CopyDisplay02 from "@onnit-js/ui/components/module/CopyDisplay02";
import Embed from "@onnit-js/ui/components/video/Embed";
import { Testimonial, TestimonialList } from "@onnit-js/ui/components/module/testimonials";
import DefaultProductPageTop from "../../components/common/layouts/DefaultProductPageTop";
import UsageAndDisclaimers from "../../components/common/UsageAndDisclaimers";
import IngredientSliders from "./IngredientSliders";
import SupplementFacts from "../../components/common/SupplementFacts";
import usePageData from "../../hooks/usePageData";

export default function Page() {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    // Used to set current products supplement facts image.
    const [currentPID, setCurrentPID] = useState<number | undefined>();
    const [showVinpoWarning, setShowVinpoWarning] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const vinpoCheck = (pid: number) => {
        if (pageData) {
            const product = pageData.products.find((product) => product.id === pid);
            if (product && product.vinpocetine_warning === 1) {
                setShowVinpoWarning(true);
            } else {
                setShowVinpoWarning(false);
            }
        }
    };

    useEffect(() => {
        if (currentPID) {
            vinpoCheck(currentPID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageData, currentPID]);

    if (!pageData || !onnitContext) {
        return null;
    }

    const IMG_PARAMS = "?q=40&fm=pjpg&auto=compress,format";
    const IMG_URL = `${onnitContext.url.cdn.static_images}/product-page/alpha-brain-instant/remediation`;

    const vinpoCtaStyle = {
        color: "green",
        textDecoration: "underline",
    };

    const orange = "#F5A800";

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                <DefaultProductPageTop
                    productNameTransform={(name) => name.replace(/Alpha BRAIN® Instant - /giu, "").replace(/\(Digital\)/giu, "")}
                    onProductChange={(product) => {
                        setCurrentPID(product.id);
                    }}
                >
                    <ProductImageGrid variant="layout3">
                        <Img
                            lazyLoad={false}
                            src={`${IMG_URL}/Gallery-1-920x920.png${IMG_PARAMS}`}
                            alt="Box of Alpha BRAIN® pineapple punch"
                            width={720}
                            height={720}
                        />
                        <Img
                            lazyLoad={false}
                            src={`${IMG_URL}/Gallery-2-920x920.png${IMG_PARAMS}`}
                            alt="Single packet of Alpha BRAIN® Instant"
                            width={928}
                            height={928}
                        />
                        <Picture
                            lazyLoad={false}
                            width={944}
                            height={494}
                            src={[
                                `${IMG_URL}/Gallery-3-920x920.png${IMG_PARAMS}`,
                                `${IMG_URL}/Gallery-3-1940x873.png${IMG_PARAMS}`,
                            ]}
                            alt="overhead view of 5 Boxes of different Alpha BRAIN® flavors"
                        />
                        <Img
                            lazyLoad={false}
                            src={`${IMG_URL}/Gallery-4-920x920.png${IMG_PARAMS}`}
                            alt="Alpha BRAIN® Instant packets on a table"
                            width={928}
                            height={928}
                        />
                        <Img
                            lazyLoad={false}
                            src={`${IMG_URL}/Gallery-5-920x920.png${IMG_PARAMS}`}
                            alt="Alpha BRAIN® Instant packets on a artist table"
                            width={920}
                            height={920}
                        />
                    </ProductImageGrid>
                </DefaultProductPageTop>

                <div className="hide-ie">
                    <Box backgroundColor="black" py={[6, 6, 6, 8]}>
                        <TwelveColumn display={["none", "none", "grid"]} mb={4}>
                            <Box gridColumn="2 / span 7" px={4}>
                                <Text as="h2" color="grays.3" typeStyle="text02">
                                    Alpha BRAIN
                                    <Sup variant="reg" /> Instant
                                </Text>
                            </Box>
                        </TwelveColumn>
                        <TwelveColumn display={["block", "block", "grid"]} mb={[0, 0, 6]}>
                            <Box gridColumn="7 / span 5" px={4}>
                                <Embed
                                    title="Alpha BRAIN® Instant product video"
                                    src="https://player.vimeo.com/video/894138077"
                                />
                            </Box>
                            <Box
                                gridColumn="2 / span 5"
                                display={["block", "block", "flex"]}
                                alignItems="center"
                                px={4}
                            >
                                <Text
                                    display={["block", "block", "none"]}
                                    as="h2"
                                    color="grays.3"
                                    typeStyle="text02"
                                    textTransform="uppercase"
                                    my={3}
                                >
                                    Alpha BRAIN
                                    <Sup variant="reg" /> Instant
                                </Text>

                                <Text as="h2" color={orange} typeStyle="title01" mb={2}>
                                    The ultimate way to get &ldquo;in the zone&rdquo;
                                    <Sup fontSize={12} color={orange} />
                                </Text>
                            </Box>
                        </TwelveColumn>

                        <TwelveColumn display={["block", "block", "grid"]}>
                            <Box gridColumn="2 / span 6" px={4} mb={[6, 6, 6, 8]}>
                                <Text as="p" typeStyle="text02" color="onGray">
                                    <span style={{ fontWeight: "black" }}>Alpha BRAIN</span>
                                    <Sup variant="reg" /> is a caffeine-free nootropic blend that helps optimize
                                    cognitive function,
                                    all in a delicious and convenient powdered drink mix.
                                    <Sup />
                                </Text>
                            </Box>
                        </TwelveColumn>

                        <TwelveColumn py={[6, 6, 6, 8]} display={["block", "block", "grid"]} backgroundColor="black">
                            <Box gridColumn="3 / span 7" px={4}>
                                <Text as="h2" color={orange} typeStyle="title01" mb={2}>
                                    Alpha BRAIN
                                    <Sup variant="reg" /> Instant Is For:
                                </Text>
                                <List>
                                    <ListItem typeStyle="text02" color="onGray">
                                        Students studying
                                    </ListItem>
                                    <ListItem typeStyle="text02" color="onGray">
                                        Professionals at work
                                    </ListItem>
                                    <ListItem typeStyle="text02" color="onGray">
                                        Gamers, artists, entrepreneurs
                                    </ListItem>
                                    <ListItem typeStyle="text02" color="onGray">
                                        People on the go
                                    </ListItem>
                                </List>
                            </Box>
                        </TwelveColumn>
                    </Box>

                    <CopyDisplay02 py={[4, 4, 4, 6]} dashColor={orange}>
                        <Text as="h2" typeStyle="copyDisplay02" mb={[6, 6, 6, 8]} style={{ textTransform: "none" }}>
                            GO WITH THE FLOW
                        </Text>

                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            You know those times when you’re so into what you’re doing that you can’t think about
                            anything else?
                            The days when you read half a dozen chapters, write a thousand words, or finish a work
                            assignment
                            without looking up once (and when you do, you notice it’s suddenly dark outside).
                        </Text>
                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            How’d you like to feel like that more often?
                        </Text>
                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            You can.
                        </Text>
                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            The feeling of being in the zone—what some in the psychology world call &ldquo;flow
                            state&rdquo;—is a
                            result of the brain producing more alpha waves, electrical pulses that are associated with
                            creativity and productivity. As a result, you’re able to tune out distractions, think
                            clearly,
                            and accomplish tasks.
                            <Sup fontSize={12} color="onGray" />{" "}
                            <Sup fontSize={12}>1-3</Sup>
                        </Text>
                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            <Testimonial
                                imgSrc={`${IMG_URL}/Testimonial-Joe-224x135.png`}
                                quote={`"It seems to fire up your brain at a higher RPM level..."`}
                                author="JOE ROGAN"
                            />
                        </Text>
                    </CopyDisplay02>

                    <CopyDisplay02 bg="black" pt={[0, 0, 8]} pb={[6, 6, 8]} dashColor={orange}>
                        <Text as="h2" typeStyle="copyDisplay02" color="onGray" mb={4} maxWidth="840px">
                            Can I Stack Alpha BRAIN
                            <Sup variant="reg" /> Instant With Other Onnit Products?
                        </Text>
                        <Text as="p" typeStyle="text02" color="onGray" maxWidth="840px">
                            Get the most out of Alpha BRAIN
                            <Sup variant="reg" /> Instant. We recommend the following products to support cognitive
                            performance and overall well-being
                            <Sup fontSize={12} color="onGray" />:
                        </Text>
                    </CopyDisplay02>

                    <Box py={[6, 6, 6, 8]} mx={4}>
                        <Text typeStyle="title01" textAlign={["left", "center"]} mb={4}>
                            AVAILABLE IN FIVE FLAVORS
                        </Text>
                        <TestimonialList height={[360, 200, 260, 300]}>
                            <Testimonial
                                imgSrc={`${IMG_URL}/Flavor-Ruby-Grapefruit-224x135.png${IMG_PARAMS}`}
                                quote="Enjoy the flavor combination of sweetness and tang anytime and anywhere you travel."
                                author="Ruby Grapefruit Flavored With Other Natural Flavor"
                            />
                            <Testimonial
                                imgSrc={`${IMG_URL}/Flavor-Meyer-Lemon-224x135.png${IMG_PARAMS}`}
                                quote="The Meyer variety of lemons will conjure up memories of summertime lemonade. Thought to be a cross between the classic lemon and a mandarin orange, Meyer lemons’ flavor is sweet citrus—not tangy, tart, or acidic."
                                author="Meyer Lemon Flavored With Other Natural Flavor"
                            />
                            <Testimonial
                                imgSrc={`${IMG_URL}/Flavor-Pineapple-Punch-224x135.png${IMG_PARAMS}`}
                                quote="Most of us stop drinking fruit punch when we find out about the sugar content... and because we’re not 7 anymore. This flavor takes you back to summer days of soccer games and pool parties."
                                author="Pineapple Punch Flavored With Other Natural Flavor"
                            />
                            <Testimonial
                                imgSrc={`${IMG_URL}/Flavor-Peach-224x135.png${IMG_PARAMS}`}
                                quote={`The original Alpha BRAIN<span class="oui-sup">&reg;</span> Instant flavor, and still a classic. The fresh, ripe taste is the perfect accompaniment to reading on your back porch, or working with the windows open.`}
                                author="Peach Flavored With Other Natural Flavor"
                            />
                            <Testimonial
                                imgSrc={`${IMG_URL}/Flavor-Blackberry-Lemonade-224x135.png${IMG_PARAMS}`}
                                quote={`Sweet, and a little bit tart, this one has remained a favorite of Alpha BRAIN<span class="oui-sup">&reg;</span> Instant connoisseurs since we launched it years ago.`}
                                author="Blackberry Lemonade Flavored With Other Natural Flavor"
                            />
                        </TestimonialList>
                    </Box>

                    <IngredientSliders
                        imageUrl={IMG_URL}
                    />

                    <Box py={[6, 6, 6, 8]}>
                        <ImageContentPortrait
                            py={[6, 6, 6, 8]}
                            reverse
                            image={(
                                <Img
                                    alt="Gamer in his room"
                                    placeholder={[464, 600]}
                                    src={`${IMG_URL}/Paragraph-How.png?q=40`}
                                    width={926}
                                    height={1200}
                                />
                            )}
                            content={(
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text as="h2" typeStyle="copyDisplay02" mb={[4, 4, 6]}>
                                        How Alpha BRAIN
                                        <Sup variant="reg" /> Instant Works
                                    </Text>
                                    <Text as="p" typeStyle="text02" mb={[4, 4, 5]}>
                                        If you want to help your brain think more clearly, you need to concentrate on
                                        two things:
                                        promoting the production of alpha waves in the brain and supporting
                                        acetylcholine
                                        levels—a key neurotransmitter.
                                        <Sup /> We formulated Alpha BRAIN
                                        <Sup variant="reg" /> Instant with both goals in mind.
                                    </Text>
                                </Box>
                            )}
                        />
                    </Box>

                    <Box py={[6, 6, 6, 8]}>
                        <ImageContentPortrait
                            py={[6, 6, 6, 8]}
                            reverse
                            image={(
                                <Img
                                    alt="Gamer in his room"
                                    placeholder={[464, 600]}
                                    src={`${IMG_URL}/Paragraph-Difference-920x920.png?q=40`}
                                    width={926}
                                    height={1200}
                                />
                            )}
                            content={(
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text as="h2" typeStyle="copyDisplay02" mb={[4, 4, 6]}>
                                        What's The Difference Between Alpha BRAIN
                                        <Sup variant="reg" /> and Alpha BRAIN
                                        <Sup variant="reg" /> Instant?
                                    </Text>
                                    <Text as="p" typeStyle="text02" mb={[4, 4, 5]}>
                                        Alpha BRAIN
                                        <Sup variant="reg" /> comes in two forms. Alpha BRAIN
                                        <Sup variant="reg" /> Instant is a nootropic, powdered supplement drink mix
                                        inspired by the world-renowned formula used in the original Alpha BRAIN
                                        <Sup variant="reg" /> capsules.
                                        <Sup /> It was designed for those who want an alternative to swallowing capsules.
                                        Alpha BRAIN
                                        <Sup variant="reg" /> Instant dissolves easily into water, making a fast,
                                        delicious drink.
                                    </Text>
                                    <Text as="p" typeStyle="text02" mb={[4, 4, 5]}>
                                        So, if you don’t like swallowing capsules, or carrying a pill bottle around,
                                        Alpha BRAIN
                                        <Sup variant="reg" /> Instant may be the better choice for you. It comes in
                                        packets that you can throw in a bag, purse, or pocket, and take on the go. It’s
                                        also available in a variety of tasty flavors that make it more enjoyable to
                                        consume.
                                    </Text>
                                    <Text as="p" typeStyle="text02" mb={[4, 4, 5]}>
                                        Due to its portability, Alpha BRAIN
                                        <Sup variant="reg" /> Instant is the nootropic that helps you think on your feet!
                                    </Text>
                                </Box>
                            )}
                        />
                    </Box>
                </div>

                {pageData.suggested_use && pageData.supplement_facts && (
                    <UsageAndDisclaimers
                        supplementFacts={<SupplementFacts currentPID={currentPID} onChange={vinpoCheck} />}
                        fdaWarning={false}
                    >
                        <Box>
                            <Text as="p" fontSize={0} color="grays.4" fontWeight="heavy" display="inline" mr={1}>
                                WARNING:
                            </Text>
                            <Text as="p" fontSize={0} display="inline" typeStyle="default" color="grays.4" mb={4}>
                                Consult a medical doctor before taking this or any other nutritional supplement if you
                                have or
                                suspect a medical condition and/or are taking any medications. Not to be used in
                                pregnant or
                                breastfeeding women or in adolescents under 18 years of age. Store in a cool, dry place.
                            </Text>
                            <Text as="p" fontSize={0} typeStyle="default" color="grays.4" mt={4} mb={4}>
                                <strong>KEEP OUT OF REACH OF CHILDREN.</strong>
                            </Text>
                            <Text as="p" fontSize={0} color="grays.4" fontWeight="bold" mb={4}>
                                <Sup /> THESE STATEMENTS HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG ADMINISTRATION.
                                THIS PRODUCT IS NOT INTENDED TO DIAGNOSE, TREAT, CURE, OR PREVENT ANY DISEASE
                            </Text>
                            <Text as="p" fontSize={0} color="grays.4" fontWeight="bold" mb={4}>
                                BioPerine
                                <Sup variant="reg" /> is the registered trademark of Sabinsa Corporation.
                            </Text>
                        </Box>
                        {showVinpoWarning && (
                            <Text fontSize={0} color="grays.4">
                                <strong>Warning:</strong> This product contains Vinpocetine, which may cause a
                                miscarriage or harm fetal development.{" "}
                                <a style={vinpoCtaStyle} href="https://www.onnit.com/vinpocetine-warning/">
                                    Learn more
                                </a>
                            </Text>
                        )}
                    </UsageAndDisclaimers>
                )}

                <Box py={[6, 6, 6, 8]} display="flex" justifyContent="center" mx={4}>
                    <Box display="flex" flexDirection="column" maxWidth="670px">
                        <Text as="p" fontSize={2} typeStyle="default" mb={4} color="grays.6">
                            REFERENCES
                        </Text>
                        <Text as="p" fontSize={2} typeStyle="default" mb={4} color="grays.6">
                            1. Csikszentmihalyi, Mihaly. "Mihaly Csikszentmihalyi." Mihály Csíkszentmihályi (2004): 1.
                        </Text>
                        <Text as="p" fontSize={2} typeStyle="default" mb={4} color="grays.6">
                            2. Csikszentmihalyi, M. (1988). The flow experience and its significance for human
                            psychology.
                            In M. Csikszentmihalyi & I. Csikszentmihalyi (Eds.), Optimal Experience: Psychological
                            Studies of
                            Flow in Consciousness. Cambridge: Cambridge University Press.
                            doi:10.1017/CBO9780511621956.002
                        </Text>
                        <Text as="p" fontSize={2} typeStyle="default" mb={4} color="grays.6">
                            3. Csikszentmihalyi, Mihaly (1990). Flow: The Psychology of Optimal Experience. Harper
                            Perennial Modern Classics.
                        </Text>
                    </Box>
                </Box>
            </>
        </ThemeProvider>
    );
}
